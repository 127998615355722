import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appNoSpaces]'
})
export class NoSpacesDirective {

  // constructor(private ngControl: NgControl) { }

  // @HostListener('input', ['$event'])
  // onInputChange(event: Event): void {
  //   const input = event.target as HTMLInputElement;
  //   // Remove spaces and keep only numeric characters
  //                                           // for space     // for string.
  //   const noSpacesValue = input.value.replace(/\s/g, ''); // Remove all spaces
  //   this.ngControl.control?.setValue(noSpacesValue);
  // }
  
  
  private regex: RegExp = new RegExp(/^[a-zA-Z0-9]*$/);  // Only allow alphanumeric characters

  constructor(private ngControl: NgControl) {}

  @HostListener('input', ['$event']) onInputChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    let inputValue = inputElement.value;

    // Remove any space or special symbol by allowing only alphanumeric values
    inputValue = inputValue.replace(/[^a-zA-Z0-9]/g, '');

    // Set the new value in the input control
    this.ngControl.control?.setValue(inputValue);
  }

}