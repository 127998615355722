<div matDialogTitle class="flex justify-between items-center" mat-dialog-title>
    <h2 class="headline m-0">Generate Link</h2> 
    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
        <mat-icon [icIcon]="icClose"></mat-icon>
    </button>
</div>
<mat-divider class="-mx-6 text-border"></mat-divider>
<mat-dialog-content class="py-3">
    <span class="label">Link Preview</span>
    <div class="px-3 break-all shadow-1 mt-1 mb-3 rounded-sm py-4 border bg-base" *ngIf="this._data.generateLinkFor == 'Shopping'">
        "{{this.staticLink}}<ng-container *ngFor="let group of this.linkRow['controls'];let i = index">{{group.value.label ? '&'+group.value.label : ''}}{{group.value.value ? '='+group.value.value : ''}}</ng-container>"
    </div>
    <div class="px-3 break-all shadow-1 mt-1 mb-3 rounded-sm py-4 border bg-base" *ngIf="this._data.generateLinkFor == 'delivery'">
        "{{this.staticLink}}<ng-container *ngFor="let group of this.linkRow['controls'];let i = index">{{group.value.label ? '&'+group.value.label : ''}}{{group.value.value ? '='+group.value.value : ''}}</ng-container>"
    </div>
    <mat-divider class="-mx-6 mt-6 mb-2 text-border"></mat-divider>
    <form [formGroup]="this.form">
        <div class="flex justify-between items-center">
            <span class="label">Customize Link</span>
            <button (click)="addNewRowByAction()" mat-icon-button>
                <mat-icon [icIcon]="icAdd"></mat-icon>
            </button>
        </div>
        <div class="flex flex-col overflow-y-auto" style="max-height: 250px;">
            <div formArrayName="linkRow" class="linkRow relative" *ngFor="let group of this.linkRow['controls'];let linkRowIndex = index">
                <div class="w-full flex gap-2" [formGroup]="group">
                    <div class="flex w-full flex-col">
                        <span class="label">Additional Parameter</span>
                        <mat-form-field appearance="outline">
                            <input appNoSpaces type="text" formControlName="label" matInput required>
                            <mat-error *ngIf="group.get('label').hasError('required')">Label is required</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="flex w-full flex-col">
                        <span class="label">Parameter Value</span>
                        <mat-form-field appearance="outline">
                            <input appNoSpaces type="text" formControlName="value" matInput required>
                            <mat-error *ngIf="group.get('value').hasError('required')">value is required</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div (click)="removeLinkRow(linkRowIndex)" *ngIf="linkRowIndex >= 1" class="absolute cursor-pointer remove_btn top-0 right-0">
                    <mat-icon [icIcon]="icRemove"></mat-icon>
                </div>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-divider class="-mx-6 text-border"></mat-divider>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close type="button">Cancel</button>
    <button color="primary" mat-button (click)="onSubmit()" type="submit" [disabled]="this.linkRow['controls'].length != 1 ? form.invalid : false">{{this.mode == 'add' ? 'Generate Link' : 'Update Link'}}</button>
</mat-dialog-actions>