import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import icClose from '@iconify/icons-ic/twotone-close';
import icAdd from '@iconify/icons-ic/add';
import icRemove from '@iconify/icons-ic/remove-circle';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'vex-add-edit-link-dialog',
  templateUrl: './add-edit-link-dialog.component.html',
  styleUrls: ['./add-edit-link-dialog.component.scss']
})
export class AddEditLinkDialogComponent implements OnInit {
  form:FormGroup;
  icClose = icClose;
  icAdd  =  icAdd;
  icRemove = icRemove;
  mode = 'add';
  constructor(
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private dialogRef: MatDialogRef<AddEditLinkDialogComponent>,
    private formBuilder : FormBuilder
  ) {
    console.log("_data==>>", this._data.link);
    
    if(this._data?.link){
      this.mode = 'update';
      this.createForm();  
      const separateLink = this.getQueryParams(this._data.link)
      console.log("separateLink", );
      
      let isFirstRow = separateLink['affiliateId'] ? true : false;
      for (const property in separateLink) {
        console.log(`${property}: ${separateLink[property]}`);
        if(isFirstRow){
          isFirstRow = false;  // Skip the first property
          continue;         // Move to the next iteration
        }
        this.addDropRow(property, separateLink[property]);
      }

      console.log("form", this.form);

    }else{
      this.mode = 'add';
      this.createForm();
      this.addDropRow();
    }


  
  
  
  }

  ngOnInit(): void {
  }

  createForm(link?){
    this.form = this.formBuilder.group({
      linkRow : this.formBuilder.array([]),
    })
  }

  getQueryParams(url) {
    // Extract the query parameters from the URL
    const queryParamsString = url.split('?').pop(); // Get the part after the '?'
    
    // Split the query parameters by '&' and handle cases where multiple '?' exist
    const paramsArray = queryParamsString.split('&');

    // Initialize an empty object to store key-value pairs
    const queryParams = {};

    // Loop through each parameter and store it in the object
    paramsArray.forEach(param => {
        const [key, value] = param.split('=');
        if (key && value) {
            queryParams[key] = value;
        }
    });

    return queryParams;
  }

  
	// form array for dropoffLocation 
	get linkRow(): FormArray {
		return this.form.get("linkRow") as FormArray;
	}

  public addDropRow(label?,dataValue?){
    (<FormArray>this.form.get("linkRow")).push(this.formBuilder.group({
      label: [label || '', Validators.required],
      value: [dataValue || '', Validators.required],
		}))
  }

  public addNewRowByAction(){
    if(this.form.invalid) this.form.markAllAsTouched();
    else this.addDropRow();
  }

  public removeLinkRow(index){
    (<FormArray>this.form.get('linkRow')).removeAt(index);
  }

  onSubmit(){
    this.dialogRef.close(this.linkRow.value);
  }
}
